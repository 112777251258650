import { Box } from '@mui/material';
import PropTypes from 'prop-types';

const Wrapper = ({ children, id }) => {
  return (
    <Box
      id={id}
      sx={{
        minHeight: '100%',
        py: 3,
      }}
    >
      {children}
    </Box>
  );
};

Wrapper.defaultProps = {
  id: null,
};

Wrapper.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Wrapper;
