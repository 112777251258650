const STATUS = {
  REQUESTED: 'requested',
  PENDING: 'pending',
  HOLDING: 'on_holding',
  PROGRESS: 'in_progress',
  FINISHED: 'finished',
};

const DATE_FORMATS = {
  basic_date: 'yyyyMMdd',
  extended_date: 'yyyy-MM-dd',
  basic_week_date: 'yyyyWwwD',
  extended_week_date: 'yyyy-Www-D',
  basic_ordinal_date: 'yyyyDDD',
  extended_ordinal_date: 'yyyy-DDD',
  basic_time: 'hhmmss',
  extended_time: 'hh:mm:ss',
  time_with_decimal_fraction: 'hhmmss.sss',
  extended_time_with_decimal_fraction: 'hh:mm:ss.sss',
  basic_date_time: 'yyyyMMddThhmmss',
  extended_date_time: 'yyyy-MM-ddThh:mm:ss',
  date_time_with_time_zone: 'yyyy-MM-ddThh:mm:ss±hh:mm',
  extended_date_time_with_fractional_seconds_and_time_zone:
    'yyyy-MM-ddThh:mm:ss.sss±hh:mm',
  date_time_with_fractional_seconds_and_offset: "yyyy-MM-dd'T'HH:mm:ss.SSSxx'",
};

const ROLES = {
  ADMINS: 'Admins',
  PRODUCT_MANAGERS: 'ProductManagers',
  PRODUCTION_MANAGEMENTS: 'ProductionManagements',
};

module.exports = {
  STATUS,
  DATE_FORMATS,
  ROLES,
};
