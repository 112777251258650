import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button } from '@mui/material';
import PropTypes from 'prop-types';

const CustomButton = ({
  title,
  type,
  color,
  onClick,
  isLoading,
  shadow,
  action,
  isDisabled,
}) => {
  return (
    <Box sx={{ flex: 1, boxShadow: shadow ? 'subtle' : 'none' }}>
      {isLoading ? (
        <LoadingButton
          sx={{ width: '100%', bgcolor: '#F8F8F8' }}
          loading={isLoading}
        >
          {title}
        </LoadingButton>
      ) : (
        <Button
          sx={{ width: '100%' }}
          variant={type}
          color={color}
          onClick={onClick}
          type={action}
          disabled={isDisabled}
        >
          {title}
        </Button>
      )}
    </Box>
  );
};

CustomButton.defaultProps = {
  type: 'contained',
  color: 'primary',
  isLoading: false,
  shadow: true,
  action: 'button',
  isDisabled: false,
};

CustomButton.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['contained', 'outlined', 'text']),
  color: PropTypes.oneOf(['primary', 'error', 'success', 'warning', 'info']),
  action: PropTypes.oneOf(['button', 'submit', 'reset']),
  onClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  shadow: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

export default CustomButton;
