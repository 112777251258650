import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import axios from '../lib/axios';

import { logout } from './auth';

const initialState = {
  users: null,
  isLoading: false,
};

const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    _get: (state) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    _getSuccess: (state, { payload }) => {
      return {
        ...state,
        users: payload,
        isLoading: false,
        error: false,
      };
    },
    _getFailure: (state) => {
      return {
        ...state,
        users: null,
        isLoading: false,
        error: true,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout.fulfilled, () => {
      return initialState;
    });
  },
});

const { _get, _getSuccess, _getFailure } = slice.actions;

export default slice.reducer;

export const fetch = createAsyncThunk(
  'users/fetchProjectManager',
  async (_, { dispatch }) => {
    dispatch(_get());
    try {
      const { data } = await axios.get('/users?role=ProductManagers');

      const users = data.map((user) => ({
        ...user,
        fullName: `${user.name} ${user.family_name}`,
      }));

      dispatch(_getSuccess(users));
    } catch (error) {
      dispatch(_getFailure());
    }
  },
);
