import { Box } from '@mui/material';
import PropTypes from 'prop-types';

import CustomButton from '../Button/CustomButton';
import PopUp from '../PopUp';
import SectionHeader from '../SectionHeader';

const ConfirmationPopUpDialog = ({
  title,
  description,
  isVisible,
  setIsVisible,
  onCancel,
  onConfirm,
  cancelTitle,
  confirmTitle,
  isLoading,
  color,
}) => (
  <PopUp
    maxWidth="sm"
    isFullWidth={false}
    isVisible={isVisible}
    setIsVisible={() => setIsVisible(false)}
  >
    <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
      <SectionHeader title={title} description={description} />
      <Box
        sx={{ display: 'flex', flexDirection: 'row', gap: 2, flexWrap: 'wrap' }}
      >
        <CustomButton
          title={cancelTitle}
          type="outlined"
          color="primary"
          shadow={false}
          isDisabled={isLoading}
          onClick={() => {
            onCancel();
          }}
        />
        <CustomButton
          title={confirmTitle}
          color={color}
          isLoading={isLoading}
          onClick={() => {
            onConfirm();
          }}
        />
      </Box>
    </Box>
  </PopUp>
);

ConfirmationPopUpDialog.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired,
  setIsVisible: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  cancelTitle: PropTypes.string.isRequired,
  confirmTitle: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  color: PropTypes.string.isRequired,
};

export default ConfirmationPopUpDialog;
