/**
 * Translates a given key using a provided dictionary of translations.
 * @param {string} key - The key to be translated.
 * @param {Object.<string, string>} translationDict - The dictionary of translations.
 * @returns {string} The translated value or the original key if not found.
 */
function translateKeys(key, translationDict) {
  return translationDict[key] || key;
}

export default translateKeys;
