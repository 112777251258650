import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';

const SectionHeader = ({ title, description, isPrimary }) => {
  return (
    <Box>
      <Typography variant={isPrimary ? 'h5' : 'h6'}>{title}</Typography>
      <Typography variant={isPrimary ? 'subtitle1' : 'body4'}>
        {description}
      </Typography>
    </Box>
  );
};

SectionHeader.defaultProps = {
  isPrimary: false,
};

SectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  isPrimary: PropTypes.bool,
};

export default SectionHeader;
