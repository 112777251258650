import { Box, Typography, Tooltip } from '@mui/material';
import { differenceInDays, isBefore, isAfter, format } from 'date-fns';
import React from 'react';

const Event = ({ event, startDate, endDate, monthDays }) => {
  const { name, startDay, endDay, direction } = event;

  const eventStartDate = new Date(startDay);
  const eventEndDate = new Date(endDay);

  const startOffset = differenceInDays(eventStartDate, startDate);
  const endOffset = differenceInDays(eventEndDate, startDate);

  const startDayOffset = Math.max(0, startOffset);
  const endDayOffset = Math.min(monthDays, endOffset + 1);

  const eventLength = endDayOffset - startDayOffset;

  if (isBefore(eventEndDate, startDate) || isAfter(eventStartDate, endDate)) {
    return null; // Event is out of the current month range
  }

  return (
    <Tooltip
      title={`${name} (${direction}): ${format(
        eventStartDate,
        'dd/MM/yyyy',
      )} - ${format(eventEndDate, 'dd/MM/yyyy')}`}
      arrow
    >
      <Box
        sx={{
          gridColumn: `${startDayOffset + 1} / span ${eventLength}`,
          backgroundColor: direction === 'AR' ? '#90caf9' : '#ffcc80',
          borderRadius: '0px 16px 16px 0px',
          height: '30px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        <Typography variant="body2" noWrap sx={{ color: '#FFFFFF' }}>
          {name} - {direction}
        </Typography>
      </Box>
    </Tooltip>
  );
};

export default Event;
