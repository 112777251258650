import { createTheme } from '@mui/material/styles';

const commonTextStyles = {
  color: '#262626',
};

const theme = createTheme({
  typography: {
    fontFamily: 'Roboto',
    h1: commonTextStyles,
    h2: commonTextStyles,
    h3: commonTextStyles,
    h4: commonTextStyles,
    h5: {
      ...commonTextStyles,
      fontWeight: '500',
    },
    h6: {
      ...commonTextStyles,
      fontSize: '1.125rem',
    },
    subtitle2: {
      ...commonTextStyles,
      fontWeight: 'lighter',
    },
    body2: {
      ...commonTextStyles,
      fontWeight: '500',
    },
    body3: {
      ...commonTextStyles,
      fontSize: '0.875rem',
      fontWeight: '400',
    },
    body4: {
      ...commonTextStyles,
      fontSize: '0.875rem',
      fontWeight: '200',
    },
  },
  palette: {
    primary: {
      main: '#3C78F0',
      light: '#53D4FF',
      dark: '#4054B2',
      contrastText: '#ffffff',
    },
    success: {
      main: '#7BC67E',
      contrastText: '#ffffff',
    },
    error: {
      main: '#F44336',
      contrastText: '#ffffff',
    },
    warning: {
      main: '#FFB547',
      contrastText: '#ffffff',
    },
    disabled: {
      main: '#9E9E9E',
      contrastText: '#ffffff',
    },
    background: {
      main: '#F8F8F8',
    },
    white: {
      main: '#fff',
    },
  },
  shadows: {
    subtle: '0px 1px 10px 1px rgba(80, 80, 80, 0.10)',
  },
});

export default theme;
