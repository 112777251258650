import { Box, Typography } from '@mui/material';
import Lottie from 'react-lottie';

import animationData from '../../assets/lottiefiles/404.json';

const NotFound = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
  };

  return (
    <Box sx={{ mt: 16 }}>
      <Lottie options={defaultOptions} height={350} width={350} />
      <Typography variant="h5" align="center">
        Página não encontrada.
      </Typography>
    </Box>
  );
};

export default NotFound;
