import GuestGuardMiddleware from './components/GuestGuardMiddleware';
import NotFound from './components/NotFound';
import AuthGuardMiddleware from './pages/AuthGuardMiddleware';
import Chat from './pages/Chat';
import Dock from './pages/Dock';
import Login from './pages/Login';
import Requests from './pages/Requests/index';

const routes = [
  {
    path: '/solicitacoes',
    element: (
      <AuthGuardMiddleware>
        <Requests />
      </AuthGuardMiddleware>
    ),
  },
  {
    path: '/dock',
    element: (
      <AuthGuardMiddleware>
        <Dock />
      </AuthGuardMiddleware>
    ),
  },
  {
    path: '/conversas/*',
    element: <Chat />,
  },
  {
    path: '/',
    element: (
      <GuestGuardMiddleware>
        <Login />
      </GuestGuardMiddleware>
    ),
  },
  {
    path: '*',
    element: (
      <AuthGuardMiddleware>
        <NotFound />
      </AuthGuardMiddleware>
    ),
  },
];

export default routes;
