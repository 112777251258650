import { Navigate } from 'react-router-dom';

import useAuth from '../hooks/useAuth';

const GuestGuardMiddleware = ({ children }) => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <Navigate to="/solicitacoes" />;
  }

  return children;
};

export default GuestGuardMiddleware;
