import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import axios from '../lib/axios';

import { logout } from './auth';

const initialState = {
  locations: null,
  isLoading: false,
  error: null,
  success: null,
};

const slice = createSlice({
  name: 'locations',
  initialState,
  reducers: {
    _get: (state) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    _getSuccess: (state, { payload }) => {
      return {
        ...state,
        locations: payload,
        isLoading: false,
        error: false,
      };
    },
    _getFailure: (state) => {
      return {
        ...state,
        locations: null,
        isLoading: false,
        error: true,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout.fulfilled, () => {
      return initialState;
    });
  },
});

const { _get, _getSuccess, _getFailure } = slice.actions;

export default slice.reducer;

export const fetch = createAsyncThunk(
  'locations/fetch',
  async (_, { dispatch }) => {
    dispatch(_get());
    try {
      const { data } = await axios.get('/locations');

      dispatch(_getSuccess(data));
    } catch (error) {
      dispatch(_getFailure());
    }
  },
);
