import { Box, Container, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';

import animationData from '../../assets/lottiefiles/404.json';
import CustomButton from '../Button/CustomButton';

const Error = ({ onClick }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
  };

  return (
    <Box sx={{ mt: 16 }}>
      <Lottie options={defaultOptions} height={350} width={350} />
      <Typography variant="h5" align="center">
        Erro ao carregar os dados.
      </Typography>
      <Container maxWidth="sm">
        <Box sx={{ mt: 2 }}>
          <CustomButton onClick={onClick} title="Carregar novamente" />
        </Box>
      </Container>
    </Box>
  );
};

Error.defaultProps = {
  onClick: () => {},
};

Error.propTypes = {
  onClick: PropTypes.func,
};

export default Error;
