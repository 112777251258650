import { Box, CircularProgress } from '@mui/material';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

import Calendar from '../../components/Calendar/index';
import Error from '../../components/Error';
import NoData from '../../components/NoData';
import SectionHeader from '../../components/SectionHeader';
import Wrapper from '../../components/Wrapper';
import { fetch } from '../../slices/docks';
import { useDispatch, useSelector } from '../../store';

const Dock = () => {
  const dispatch = useDispatch();
  const { docks, isLoading, error } = useSelector((state) => state.docks);

  useEffect(() => {
    dispatch(fetch());
  }, [dispatch]);

  const renderCalendar = () => {
    if (docks && docks.length > 0 && !isLoading) {
      return <Calendar data={docks} />;
    }

    if (docks && docks.length === 0 && !isLoading) {
      return <NoData message="Sem informações para mostrar o calendário" />;
    }

    if (error) {
      return <Error onClick={() => dispatch(fetch())} />;
    }

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );
  };

  return (
    <>
      <Helmet>
        <title>Docks | Cabnave</title>
      </Helmet>
      <Wrapper>
        <SectionHeader title="Calendário" isPrimary description="" />
        <Box sx={{ width: '100%', marginTop: '12px' }}>{renderCalendar()}</Box>
      </Wrapper>
    </>
  );
};

export default Dock;
