import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import translationDict from '../../../constants/translationDict';
import translateKeys from '../../../utils/translateKeys';
import Event from '../Event';

const Line = ({ lineName, events, startDate, endDate, monthDays, index }) => {
  const filterEvents = (_events, direction) => {
    return _events.filter((event) => {
      const eventStartDate = new Date(event.start_date);
      const eventEndDate = new Date(event.end_date);
      const lineStartDate = new Date(startDate);
      const lineEndDate = new Date(endDate);

      return (
        event.direction === direction &&
        eventEndDate >= lineStartDate &&
        eventStartDate <= lineEndDate
      );
    });
  };

  const backgroundColor =
    index % 2 === 0 ? 'rgba(240, 240, 240, 1)' : 'rgba(255, 255, 255, 1)';

  return (
    <Box mb={3} p={2} bgcolor={backgroundColor} borderRadius={1}>
      <Typography variant="h6">
        {translateKeys(lineName, translationDict)}
      </Typography>
      <Box
        display="grid"
        gridTemplateColumns={`repeat(${monthDays}, 1fr)`}
        gap={1}
        position="relative"
        mb={2}
      >
        {filterEvents(events, 'AR').map((event, idx) => (
          <Event
            // eslint-disable-next-line react/no-array-index-key
            key={`AR-${idx}`}
            event={{
              ...event,
              startDay: event.start_date,
              endDay: event.end_date,
            }}
            startDate={new Date(startDate)}
            endDate={new Date(endDate)}
            monthDays={monthDays}
          />
        ))}
      </Box>
      <Box
        display="grid"
        gridTemplateColumns={`repeat(${monthDays}, 1fr)`}
        gap={1}
        position="relative"
      >
        {filterEvents(events, 'AV').map((event, idx) => (
          <Event
            // eslint-disable-next-line react/no-array-index-key
            key={`AV-${idx}`}
            event={{
              ...event,
              startDay: event.start_date,
              endDay: event.end_date,
            }}
            startDate={new Date(startDate)}
            endDate={new Date(endDate)}
            monthDays={monthDays}
          />
        ))}
      </Box>
    </Box>
  );
};

Line.propTypes = {
  lineName: PropTypes.string.isRequired,
  events: PropTypes.arrayOf(
    PropTypes.shape({
      start_date: PropTypes.string.isRequired,
      end_date: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      direction: PropTypes.oneOf(['AR', 'AV']).isRequired,
    }),
  ).isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  monthDays: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

export default Line;
