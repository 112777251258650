import { Box, Toolbar, AppBar, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';

import DesktopLogo from '../../assets/header/cabnave_logo.svg';
import CustomButton from '../Button/CustomButton';

const Header = ({ isAuthenticated, onSignOut, navLinks }) => {
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box>
      <AppBar position="fixed" color="white">
        <Toolbar
          sx={{ display: 'flex', justifyContent: 'space-between', p: 1 }}
        >
          {mobileDevice ? (
            <img src={DesktopLogo} alt="Mobile Logo" width="160" />
          ) : (
            <img src={DesktopLogo} alt="Desktop Logo" width="200" />
          )}
          <Box sx={{ display: 'flex', gap: 1 }}>
            {isAuthenticated &&
              navLinks.map((link) => (
                <CustomButton
                  key={link.title}
                  title={link.title}
                  type="text"
                  color="primary"
                  shadow={false}
                  onClick={link.onClick}
                />
              ))}
            {isAuthenticated && (
              <CustomButton
                title="Sair"
                type="text"
                color="error"
                shadow={false}
                onClick={onSignOut}
              />
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </Box>
  );
};

Header.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  onSignOut: PropTypes.func.isRequired,
  navLinks: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    }),
  ).isRequired,
};

export default Header;
