import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Typography, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const CalendarHeader = ({
  currentMonthYear,
  handleBackClick,
  handleForwardClick,
}) => {
  return (
    <Box display="flex" alignItems="center" justifyContent="center" mb={3}>
      <IconButton onClick={handleBackClick}>
        <ArrowBackIosIcon fontSize="small" />
      </IconButton>
      <Typography variant="h5" mx={2}>
        {currentMonthYear}
      </Typography>
      <IconButton onClick={handleForwardClick}>
        <ArrowForwardIosIcon fontSize="small" />
      </IconButton>
    </Box>
  );
};

CalendarHeader.propTypes = {
  currentMonthYear: PropTypes.string.isRequired,
  handleBackClick: PropTypes.func.isRequired,
  handleForwardClick: PropTypes.func.isRequired,
};

export default CalendarHeader;
