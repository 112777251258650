import { Box } from '@mui/material';
import PropTypes from 'prop-types';

const ContentCard = ({ children }) => {
  return (
    <Box
      sx={{
        flex: 1,
        boxShadow: 'subtle',
        borderRadius: 2,
        bgcolor: 'white.main',
      }}
    >
      {children}
    </Box>
  );
};

ContentCard.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ContentCard;
