import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import ContentCard from '../ContentCard';
import SectionHeader from '../SectionHeader';

const AdditionalInformation = ({ request }) => {
  return (
    <Box>
      <SectionHeader
        title="Informações Adicionais"
        description="Informações Adicionais"
      />
      <Box sx={{ mt: 2 }}>
        <ContentCard>
          <Box
            sx={{
              p: 2,
            }}
          >
            <Typography variant="body3">{request.observation}</Typography>
          </Box>
        </ContentCard>
      </Box>
    </Box>
  );
};

AdditionalInformation.propTypes = {
  request: PropTypes.shape({
    observation: PropTypes.string,
  }).isRequired,
};

export default AdditionalInformation;
