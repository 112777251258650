import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const DaysRow = ({ days, currentDate }) => {
  const today = new Date();
  const weekdays = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];

  const dayElements = days.map((day) => {
    const date = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      day,
    );
    const isToday =
      Number(day) === today.getDay() &&
      currentDate.getMonth() === today.getMonth() &&
      currentDate.getFullYear() === today.getFullYear();

    return (
      <Box key={day} textAlign="center" width="calc(100% / 31)">
        <Typography
          variant="body2"
          sx={{
            display: 'inline-block',
            width: '30px',
            height: '30px',
            lineHeight: '30px',
            borderRadius: '50%',
            backgroundColor: isToday
              ? 'rgba(38, 176, 255, 0.5)'
              : 'transparent',
            color: isToday ? '#FFFFFF' : 'inherit',
          }}
        >
          {day}
        </Typography>
        <Typography variant="subtitle2">{weekdays[date.getDay()]}</Typography>
      </Box>
    );
  });

  return (
    <Box>
      <Box display="flex" justifyContent="space-between">
        {dayElements}
      </Box>
    </Box>
  );
};

DaysRow.propTypes = {
  days: PropTypes.arrayOf(PropTypes.number).isRequired,
  currentDate: PropTypes.instanceOf(Date).isRequired,
};

export default DaysRow;
