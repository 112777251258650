import { Box, Container } from '@mui/material';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

import LoginForm from '../../components/auth/LoginForm';
import ContentCard from '../../components/ContentCard';
import CustomSnackBar from '../../components/CustomSnackBar';
import { useSelector } from '../../store';

function Login() {
  const navigate = useNavigate();

  const { success, error, message } = useSelector((state) => state.auth);

  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleSnackBarClose = () => {
    setIsSnackBarOpen(false);
  };

  const handleError = (statusCode) => {
    if (statusCode === 401) {
      setErrorMessage('Credenciais Inválidas');
    } else {
      setErrorMessage('Ocorreu um erro ao efectuar o login.');
    }
  };

  useEffect(() => {
    if (success) {
      navigate('/solicitacoes');
    }
  }, [success]);

  useEffect(() => {
    if (error) {
      handleError(message.status);
      setIsSnackBarOpen(true);
    }
  }, [error, message]);

  return (
    <>
      <Helmet>
        <title>Login | Cabnave</title>
      </Helmet>
      <Box
        sx={{
          paddingTop: {
            xs: 4,
            sm: 6,
            md: 12,
            lg: 20,
          },
        }}
      >
        <Container maxWidth="sm">
          <ContentCard>
            <Box sx={{ width: '100%', p: 4 }}>
              <LoginForm />
            </Box>
            <CustomSnackBar
              open={isSnackBarOpen}
              message={errorMessage}
              severity="error"
              onClose={handleSnackBarClose}
            />
          </ContentCard>
        </Container>
      </Box>
    </>
  );
}

export default Login;
