import { Container } from '@mui/material';
import { addMonths, subMonths, format } from 'date-fns';
import { pt } from 'date-fns/locale';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import CalendarBody from './CalendarBody';
import CalendarHeader from './CalendarHeader';

const Calendar = ({ data }) => {
  const [currentDate, setCurrentDate] = useState(new Date());

  const handleBackClick = () => {
    setCurrentDate((prevDate) => subMonths(prevDate, 1));
  };

  const handleForwardClick = () => {
    setCurrentDate((prevDate) => addMonths(prevDate, 1));
  };

  return (
    <Container>
      <CalendarHeader
        currentMonthYear={format(currentDate, 'MMMM yyyy', {
          locale: pt,
        }).toLocaleUpperCase()}
        handleBackClick={handleBackClick}
        handleForwardClick={handleForwardClick}
      />
      <CalendarBody data={data} currentDate={currentDate} />
    </Container>
  );
};

Calendar.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      events: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          end_date: PropTypes.string.isRequired,
          start_date: PropTypes.string.isRequired,
          direction: PropTypes.oneOf(['AR', 'AV']).isRequired,
        }),
      ).isRequired,
    }).isRequired,
  ).isRequired,
};

export default Calendar;
