import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  useTheme,
} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import translationDict from '../../constants/translationDict';
import translateKeys from '../../utils/translateKeys';
import ChipButton from '../Button/ChipButton';
import ContentCard from '../ContentCard';

const ServicesInformation = ({ services }) => {
  const theme = useTheme();

  const moveOtherToLast = ([...arr]) => {
    const otherServiceIndex = arr.findIndex(
      (service) => service.id === 'OTHER',
    );
    if (otherServiceIndex !== -1) {
      const otherService = arr.splice(otherServiceIndex, 1)[0];
      arr.push(otherService);
    }
    return arr;
  };

  const sortedServices = moveOtherToLast([...services]);

  const customTableCell = (value, isLabel) => {
    return (
      <TableCell sx={{ width: !isLabel && '100%' }}>
        {isLabel ? (
          <Box>
            <ChipButton
              type="info"
              label={translateKeys(value, translationDict)}
            />
          </Box>
        ) : (
          <Box>
            <Typography
              variant="body3"
              color={!value && theme.palette.grey[500]}
              sx={{
                [theme.breakpoints.down('md')]: {
                  whiteSpace: 'nowrap',
                },
              }}
            >
              {value || 'Sem descrição adicional'}
            </Typography>
          </Box>
        )}
      </TableCell>
    );
  };

  const isLastRow = (index) => {
    return index === sortedServices.length - 1;
  };

  const noBorder = { '& td': { border: 0 } };

  const renderTable = () => {
    return (
      <>
        {sortedServices.map((service, index) => {
          return (
            <TableRow key={service.id} sx={isLastRow(index) ? noBorder : {}}>
              {customTableCell(service.id, true)}
              {customTableCell(service.description)}
            </TableRow>
          );
        })}
      </>
    );
  };

  return (
    <ContentCard>
      <TableContainer>
        <Table>
          <TableBody>{renderTable()}</TableBody>
        </Table>
      </TableContainer>
    </ContentCard>
  );
};

ServicesInformation.propTypes = {
  services: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      description: PropTypes.string,
    }),
  ).isRequired,
};

export default ServicesInformation;
