import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import useAuth from '../hooks/useAuth';

import Login from './Login';

const AuthGuardMiddleware = (props) => {
  const { children } = props;
  const auth = useAuth();
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);

  if (!auth.isAuthenticated) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }

    return <Login />;
  }

  // This is done so that in case the route changes by any chance through other
  // means between the moment of request and the render we navigate to the initially
  // requested route.
  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return (
    <>{React.Children.map(children, (child) => React.cloneElement(child))}</>
  );
};

AuthGuardMiddleware.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthGuardMiddleware;
