import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  Typography,
  TableCell,
  CircularProgress,
} from '@mui/material';
import PropTypes from 'prop-types';

import ContentCard from '../ContentCard';

const FileList = ({ files, isLoading }) => {
  const handleOpenFile = (url) => {
    window.open(url, '_blank', 'noreferrer');
  };

  const hasData = () => {
    return files.length > 0;
  };

  const noBorder = { '& td': { border: 0 } };

  const content = () => {
    return hasData() ? (
      files.map((file, index) => {
        return (
          <TableRow
            key={document.document_id}
            sx={{
              ...(index === files.length - 1 ? noBorder : {}),
              cursor: 'pointer',
            }}
            onClick={() => {
              handleOpenFile(file.url);
            }}
          >
            <TableCell sx={{ width: '100%' }}>
              <Typography variant="body3">{file.filename}</Typography>
            </TableCell>
            <TableCell sx={{ width: '100%' }}>
              <LinkOutlinedIcon />
            </TableCell>
          </TableRow>
        );
      })
    ) : (
      <TableCell sx={{ border: 0 }}>
        <Typography variant="body3">Sem dados</Typography>
      </TableCell>
    );
  };

  return (
    <ContentCard>
      <TableContainer>
        <Table>
          <TableBody>
            {isLoading ? (
              <TableCell
                sx={{
                  border: 0,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <CircularProgress size={24} />
              </TableCell>
            ) : (
              content()
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </ContentCard>
  );
};

FileList.propTypes = {
  file: PropTypes.shape({
    filename: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default FileList;
