import { CircularProgress } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import React from 'react';

const AutocompleteInput = ({
  options,
  value,
  label,
  helperText,
  onChange,
  name,
  error,
  getLabel,
  loading,
  isDisabled = false,
}) => {
  return (
    <Autocomplete
      fullWidth
      options={options}
      getOptionLabel={getLabel}
      value={value}
      onChange={(event, newValue) => onChange(newValue)}
      disabled={isDisabled}
      renderInput={(params) => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          name={name}
          label={label}
          helperText={helperText}
          error={error}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

AutocompleteInput.defaultProps = {
  helperText: null,
  value: null,
  options: [],
  error: null,
  loading: false,
  isDisabled: false,
};

AutocompleteInput.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.arrayOf(PropTypes.object),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  error: PropTypes.bool,
  loading: PropTypes.bool,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  getLabel: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
};

export default AutocompleteInput;
