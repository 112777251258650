const dateFormattedAccordingLanguage = (date, lang) => {
  const partsDate = date.split('-');
  const year = partsDate[0];
  const month = partsDate[1];
  const day = partsDate[2];

  if (lang === 'pt') {
    return `${day}/${month}/${year}`;
  }
  return date;
};

module.exports = {
  dateFormattedAccordingLanguage,
};
