import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import {
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  IconButton,
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';

import translationDict from '../../constants/translationDict';
import translateKeys from '../../utils/translateKeys';
import ChipButton from '../Button/ChipButton';
import ContentCard from '../ContentCard';

const BasicTable = ({ requests, onActionButtonClick }) => {
  const headerTable = [
    'Cod. Ref.',
    'IMO',
    'Nome do Navio',
    'Tipo de Navio',
    'Contatos',
    'Bandeira',
    'Data de Criaçāo',
    'Estado de Entrada',
    'Estado de Saída',
    'Açāo',
  ];

  const getStatusButtonLabel = (data) => {
    const isAllApproved = (statusArray) =>
      statusArray.every((item) => item.status === 'accepted');
    const hasPending = (statusArray) =>
      statusArray.some((item) => item.status === 'pending');

    if (isAllApproved(data)) {
      return <ChipButton label="Aprovado" type="approved" />;
    }

    if (hasPending(data)) {
      return <ChipButton label="Pendente" type="pending" />;
    }

    return null;
  };

  const renderCustomCell = (value) => {
    return (
      <TableCell sx={{ typography: 'body3' }}>
        {translateKeys(value, translationDict)}
      </TableCell>
    );
  };

  const renderTranslatedKey = (value) => {
    return translateKeys(value, translationDict);
  };

  return (
    <ContentCard>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="basic table">
          <TableHead>
            <TableRow>
              {headerTable.map((item) => (
                <Tooltip
                  title={item === 'Cod. Ref.' ? 'Código de Referência' : item}
                  arrow
                  key={item}
                >
                  <TableCell key={item}>{item}</TableCell>
                </Tooltip>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {requests.map((item, index) => (
              <TableRow
                key={item.id}
                sx={
                  index === requests.length - 1 ? { '& td': { border: 0 } } : {}
                }
              >
                <TableCell sx={{ typography: 'body3', textAlign: 'center' }}>
                  {translateKeys(item.reference_code, translationDict)}
                </TableCell>
                {renderCustomCell(item.ship.imo)}
                {renderCustomCell(item.ship.name)}
                {renderCustomCell(
                  item.ship.ship_type_name
                    ? renderTranslatedKey(item.ship.ship_type_name)
                    : renderTranslatedKey(item.ship.ship_type_id),
                )}
                <TableCell
                  sx={{
                    typography: 'body3',
                  }}
                >
                  <Box>{item.ship.ship_owner.email}</Box>
                  <Box>{item.ship.ship_owner.phone_number}</Box>
                </TableCell>
                {renderCustomCell(item.ship.country_id)}
                {renderCustomCell(
                  new Date(item.created_at).toLocaleDateString(),
                )}
                <TableCell>
                  {getStatusButtonLabel(item.entrance_status)}
                </TableCell>
                <TableCell>{getStatusButtonLabel(item.exit_status)}</TableCell>
                <TableCell>
                  <IconButton onClick={() => onActionButtonClick(item)}>
                    <ArrowForwardOutlinedIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </ContentCard>
  );
};

BasicTable.propTypes = {
  requests: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      entrance_status: PropTypes.arrayOf(
        PropTypes.shape({
          status: PropTypes.oneOf(['accepted', 'pending']).isRequired,
        }),
      ).isRequired,
      ship: PropTypes.shape({
        imo: PropTypes.string.isRequired,
        country_id: PropTypes.string.isRequired,
        ship_type_id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        ship_type_name: PropTypes.string,
        ship_owner: PropTypes.shape({
          email: PropTypes.string.isRequired,
          phone_number: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
  ).isRequired,
  onActionButtonClick: PropTypes.func.isRequired,
};

export default BasicTable;
