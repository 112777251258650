import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import axios from '../lib/axios';
import chatAxios from '../lib/chatAxios';

const initialState = {
  user: null,
  message: null,
  isAuthenticated: false,
  isInitialized: false,
  isLoading: null,
  error: null,
  success: null,
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    chatAxios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
    delete chatAxios.defaults.headers.common.Authorization;
  }
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    _initialize: (state) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    _initializeSuccess: (state, { payload }) => {
      return {
        ...state,
        isInitialized: true,
        isAuthenticated: true,
        user: payload,
        isLoading: false,
      };
    },
    _initializeFailure: (state) => {
      return {
        ...state,
        isInitialized: true,
        isAuthenticated: false,
        user: null,
        isLoading: false,
      };
    },
    _login: (state) => {
      return {
        ...state,
        isInitialized: true,
        isLoading: true,
      };
    },
    _loginSuccess: (state, { payload }) => {
      return {
        ...state,
        isAuthenticated: true,
        user: payload,
        success: true,
        isInitialized: false,
        isLoading: false,
      };
    },
    _loginFailure: (state, { payload }) => {
      return {
        ...state,
        isAuthenticated: false,
        isInitialized: false,
        isLoading: false,
        message: payload,
        error: true,
      };
    },
    _logout: () => {
      return initialState;
    },
  },
});

const {
  _login,
  _loginSuccess,
  _loginFailure,
  _initialize,
  _initializeSuccess,
  _initializeFailure,
  _logout,
} = slice.actions;

export default slice.reducer;

export const initialize = createAsyncThunk(
  'user/initialize',
  async (_, { dispatch }) => {
    dispatch(_initialize());
    try {
      const accessToken = window.localStorage.getItem('accessToken');

      if (accessToken) {
        setSession(accessToken);
        const response = await axios.get('/users/me');

        dispatch(_initializeSuccess(response.data));
      } else {
        dispatch(_initializeFailure());
      }
    } catch (error) {
      dispatch(_initializeFailure());
    }
  },
);
export const login = createAsyncThunk(
  'user/login',
  async ({ username, password }, { dispatch }) => {
    dispatch(_login());
    try {
      const { data } = await axios.post('/sessions', {
        username,
        password,
      });

      const { token } = data;

      setSession(token);

      const response = await axios.get('/users/me');

      dispatch(_loginSuccess(response.data));
    } catch (error) {
      dispatch(_loginFailure(error.response));
    }
  },
);

export const logout = createAsyncThunk(
  'user/logout',
  async (_, { dispatch }) => {
    dispatch(_logout());
    setSession();
  },
);
