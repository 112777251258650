import { format, parseISO } from 'date-fns';

import { DATE_FORMATS } from './constants';
/**
 * Adds the current local time to a given date string.
 *
 * @param {string} date - The date string in ISO format to which the current local time will be added.
 * @returns {string} - The formatted date string with the current local time in ISO-8601 format.
 */
const addCurrentLocalTime = (date) => {
  const dateParsed = parseISO(date);
  const currentDate = new Date();

  const utcDate = new Date(
    Date.UTC(
      dateParsed.getUTCFullYear(),
      dateParsed.getUTCMonth(),
      dateParsed.getUTCDate(),
      currentDate.getUTCHours(),
      currentDate.getUTCMinutes(),
      currentDate.getUTCSeconds(),
      currentDate.getUTCMilliseconds(),
    ),
  );

  const dateTimeFormat =
    DATE_FORMATS.date_time_with_fractional_seconds_and_offset; // Timezone (ISO-8601 w/o Z)

  const formattedDate = format(utcDate, dateTimeFormat);

  return formattedDate;
};

export default addCurrentLocalTime;
