import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
} from '@mui/material';
import PropTypes from 'prop-types';

import ChipButton from '../Button/ChipButton';
import ContentCard from '../ContentCard';

const RequestStatusTable = ({ data }) => {
  const headerTable = ['Email', 'Estado de Entrada', 'Estado de Saída'];

  const getStatusButtonLabel = (status) => {
    if (status === 'accepted') {
      return <ChipButton label="Aprovado" type="approved" />;
    }
    return <ChipButton label="Pendente" type="pending" />;
  };

  const renderCustomCell = (value, isStatus) => {
    return (
      <TableCell
        key={Math.random()}
        sx={{ typography: isStatus ? {} : 'body3' }}
      >
        {value}
      </TableCell>
    );
  };

  return (
    <ContentCard>
      <TableContainer>
        <Table aria-label="basic table">
          <TableHead>
            <TableRow>
              {headerTable.map((item) => (
                <TableCell key={item}>{item}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.entrance_status.map((entranceItem, index) => {
              const exitItem = data.exit_status.find(
                (item) => item.email === entranceItem.email,
              );

              return (
                <TableRow
                  key={entranceItem.email}
                  sx={
                    index === data.entrance_status.length - 1
                      ? { '& td': { border: 0 } }
                      : {}
                  }
                >
                  {renderCustomCell(entranceItem.email)}
                  {renderCustomCell(
                    getStatusButtonLabel(entranceItem.status),
                    true,
                  )}
                  {exitItem && (
                    <>
                      {renderCustomCell(
                        getStatusButtonLabel(exitItem.status),
                        true,
                      )}
                    </>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </ContentCard>
  );
};

RequestStatusTable.propTypes = {
  data: PropTypes.shape({
    exit_status: PropTypes.arrayOf(
      PropTypes.shape({
        email: PropTypes.string.isRequired,
        status: PropTypes.oneOf(['accepted', 'pending']).isRequired,
      }),
    ).isRequired,
    entrance_status: PropTypes.arrayOf(
      PropTypes.shape({
        email: PropTypes.string.isRequired,
        status: PropTypes.oneOf(['accepted', 'pending']).isRequired,
      }),
    ).isRequired,
  }).isRequired,
};

export default RequestStatusTable;
