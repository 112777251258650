const translationDict = {
  email: 'Email',
  name: 'Nome',
  phone_number: 'Contato',

  country_id: 'Bandeira',
  imo: 'IMO',
  width: 'Dimensão Total da Boca (m)',
  length: 'Comprimento (m)',
  registration_port: 'Porto de Registro',
  ship_type_id: 'Tipo de Navio',
  year: 'Ano de Fábrico',
  overall_length: 'Comprimento fora-fora (m)',
  draft: 'Calado',
  depth: 'Pontal',
  light_ship_draft: 'Calado do Navio na Condiçāo Leve',
  ship_building_yards_name: 'Nome do Estaleiro de Fabrico',
  ship_building_yards_country: 'País do Estaleiro de Fabrico',
  onboard_electrical_power_system_voltage:
    'Voltagem do Sistema de Alimentaçāo Elétrica a Bordo',
  onboard_electrical_power_system_frequency:
    'Frequência do Sistema de Alimentaçāo Elétrica a Bordo(Hz)',
  propulsion_system_type_id: 'Tipo de Sistema Propulsivo',
  class_and_classification_society: 'Classe e Entidade Classificadora',
  light_displacement: 'Deslocamento leve (T)',
  maximum_displacement: 'Deslocamento máximo (T)',

  pending: 'Pendente',
  in_progress: 'Em Curso',
  on_holding: 'Trabalho Finalizado',
  finished: 'Pedido Finalizado',
  PASSENGER: 'Passageiros',
  CONTAINER: 'Contentor',
  PLEASURE_CRAFT: 'Embarcaçāo de Recreio',
  GENERAL_CARGO: 'Carga Geral',
  FISHING: 'Pesca',
  TANKER: 'Tanker',
  OTHER: 'Outro',
  BOILERMAKING: 'Caldeiraria',
  CARPENTRY: 'Carpintaria',
  ELECTRICITY: 'Eletricidade',
  NON_DESTRUCTIVE_TESTING: 'Ensaios Não Destrutivos',
  MECHANICS: 'Mecânica',
  MECHANICAL_LOCKSMITHING: 'Serralharia Mecânica',
  HULL_TREATMENT: 'Tratamento De Casco',
  PARK_1: 'Parque 1',
  PARK_2: 'Parque 2',
  PARK_3: 'Parque 3',
  PARK_4: 'Parque 4',
  PARK_5: 'Parque 5',
  PARK_6: 'Parque 6',
  entrance_date: 'Data de Entrada',
  exit_date: 'Data de Saída',
  reference_code: 'Código de Referência',

  submitter_full_name: 'Nome Completo',
  submitter_phone_number: 'Telefone',
  submitter_email: 'Email',
  submitter_cell_phone: 'Telemóvel',
  submitter_country: 'País de Origem do Preenchimento do Formulário',
  submitter_position_within_the_company: 'Posiçāo dentro da Empresa',

  ship_owner_name: 'Nome da Empresa',
  ship_owner_email: 'Email',
  ship_owner_phone_number: 'Telefone',
  ship_owner_cell_phone: 'Telemóvel',
  ship_owner_street: 'Rua',
  ship_owner_city: 'Cidade',
  ship_owner_mailbox: 'Caixa Postal',
  ship_owner_country: 'País',
  ship_owner_fiscal_number: 'Número de Identificação Fiscal',

  ship_information_imo: 'IMO',
  ship_information_name: 'Nome',
  ship_information_country: 'Bandeira',
  ship_information_registration_port: 'Porto de Registo',
  ship_information_building_yards_name: 'Nome do Estaleiro de Fabrico',
  ship_information_building_yards_country: 'País do Estaleiro de Fabrico',
  ship_information_year: 'Ano De Fabrico',
  ship_information_ship_type_name: 'Tipo de Navio',
  ship_information_ship_class_and_classification_society:
    'Classe e Entidade Classificadora',
  ship_information_length: 'Comprimento (m)',
  ship_information_overall_length: 'Comprimento Fora-Fora (m)',
  ship_information_width: 'Dimensāo Total da Boca (m)',
  ship_information_light_ship_draft: 'Calado do Navio na Condiçāo Leve (m)',
  ship_information_light_displacement: 'Deslocamento Leve (T)',
  ship_information_maximum_displacement: 'Deslocamento Máximo (T)',
  ship_information_draft: 'Calado (m)',
  ship_information_depth: 'Pontal (m)',
  ship_information_propulsion_system_type_id: 'Tipo de Sistema Propulsivo',
  ship_information_onboard_electrical_power_system_voltage:
    'Voltagem do Sistema de Alimentaçāo Elétrica a Bordo',
  ship_information_onboard_electrical_power_system_frequency:
    'Frequência do Sistema de Alimentaçāo Elétrica a Bordo (Hz)',
  VARIABLE_PITCH: 'Passo Variável',
  FIXED_STEP: 'Fixo',
};

export default translationDict;
