import { Box } from '@mui/material';
import { eachDayOfInterval, endOfMonth, format, startOfMonth } from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';

import DaysRow from '../DaysRow';
import Line from '../Line';

const CalendarBody = ({ data, currentDate }) => {
  const startDate = startOfMonth(currentDate);
  const endDate = endOfMonth(currentDate);
  const days = eachDayOfInterval({ start: startDate, end: endDate }).map(
    (date) => ({
      day: format(date, 'd'),
      weekday: format(date, 'E'),
    }),
  );

  return (
    <Box mt={3}>
      <DaysRow days={days.map((d) => d.day)} currentDate={currentDate} />
      {data.map((line, index) => (
        <Line
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          lineName={line.name}
          events={line.events}
          startDate={startDate}
          endDate={endDate}
          monthDays={days.length}
          index={0}
        />
      ))}
    </Box>
  );
};

CalendarBody.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      events: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          end_date: PropTypes.string.isRequired,
          start_date: PropTypes.string.isRequired,
          direction: PropTypes.oneOf(['AR', 'AV']).isRequired,
        }),
      ).isRequired,
    }).isRequired,
  ).isRequired,
  currentDate: PropTypes.instanceOf(Date).isRequired,
};

export default CalendarBody;
