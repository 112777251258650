import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import axios from '../lib/axios';
import addCurrentLocalTime from '../utils/addCurrentLocalTime';
import { DATE_FORMATS, STATUS } from '../utils/constants';
import formatDate from '../utils/formatDate';

import { logout } from './auth';

const initialState = {
  requestsUnderReview: null,
  pendingRequests: null,
  finishedRequests: null,
  requestsInProgress: null,
  request: null,
  isLoading: false,
  isLoadingApproveOrDeny: false,
  isLoadingLocation: false,
  error: null,
  errorLocation: null,
  success: null,
  successLocation: null,
  isLoadingGetById: false,
  errorGetById: null,
  isLoadingAction: false,
  successAction: null,
  errorAction: null,
  files: null,
  isLoadingFiles: false,
  filesError: null,
};

const slice = createSlice({
  name: 'requests',
  initialState,
  reducers: {
    _clearCurrentRequest: (state) => {
      return {
        ...state,
        request: null,
      };
    },
    _setCurrentRequest: (state, { payload }) => {
      return {
        ...state,
        request: payload,
      };
    },
    _approveOrDenied: (state) => {
      return {
        ...state,
        isLoadingApproveOrDeny: true,
      };
    },
    _approvedOrDeniedSuccess: (state) => {
      return {
        ...state,
        isLoadingApproveOrDeny: false,
        error: false,
        success: true,
      };
    },
    _approvedOrDeniedFailure: (state) => {
      return {
        ...state,
        isLoadingApproveOrDeny: false,
        error: true,
      };
    },
    _get: (state) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    _getSuccess: (state, { payload }) => {
      return {
        ...state,
        requestsUnderReview: payload.requestsUnderReview,
        pendingRequests: payload.pendingRequests,
        finishedRequests: payload.finishedRequests,
        requestsInProgress: payload.requestsInProgress,
        isLoading: false,
        error: false,
      };
    },
    _getFailure: (state) => {
      return {
        ...state,
        requestsUnderReview: null,
        pendingRequests: null,
        finishedRequests: null,
        requestsInProgress: null,
        isLoading: false,
        error: true,
      };
    },
    _getById: (state) => {
      return {
        ...state,
        isLoadingGetById: true,
      };
    },
    _getByIdSuccess: (state) => {
      return {
        ...state,
        isLoadingGetById: false,
        errorGetById: false,
      };
    },
    _getByIdFailure: (state) => {
      return {
        ...state,
        isLoadingGetById: false,
        errorGetById: true,
      };
    },
    _clearError: (state) => {
      return {
        ...state,
        error: null,
      };
    },
    _clearActionError: (state) => {
      return {
        ...state,
        errorAction: null,
      };
    },
    _clearSuccess: (state) => {
      return {
        ...state,
        success: false,
      };
    },
    _addLocationOrDate: (state) => {
      return {
        ...state,
        isLoadingLocation: true,
      };
    },
    _addLocationOrDateSuccess: (state) => {
      return {
        ...state,
        successLocation: true,
        isLoadingLocation: false,
      };
    },
    _addLocationOrDateFailure: (state) => {
      return {
        ...state,
        errorLocation: true,
        isLoadingLocation: false,
      };
    },
    _clearAddLocationOrDateSuccess: (state) => {
      return {
        ...state,
        successLocation: null,
      };
    },
    _clearAddLocationOrDateError: (state) => {
      return {
        ...state,
        errorLocation: null,
      };
    },
    _clearActionSuccess: (state) => {
      return {
        ...state,
        successAction: false,
      };
    },
    _action: (state) => {
      return {
        ...state,
        isLoadingAction: true,
      };
    },
    _actionSuccess: (state) => {
      return {
        ...state,
        isLoadingAction: false,
        successAction: true,
      };
    },
    _addProjectManagerSuccess: (state, { payload }) => {
      const { requestsInProgress, request } = state;

      if (requestsInProgress) {
        const updatedRequest = {
          ...request,
          product_manager_id: payload.product_manager_id,
        };

        const updatedRequestsInProgress = requestsInProgress.map((pending) => {
          if (pending.id === payload.id) {
            return {
              ...pending,
              product_manager_id: payload.product_manager_id,
            };
          }
          return pending;
        });

        return {
          ...state,
          request: updatedRequest,
          requestsInProgress: updatedRequestsInProgress,
          isLoadingAction: false,
          successAction: true,
        };
      }

      return {
        ...state,
        isLoadingAction: false,
        successAction: true,
      };
    },
    _actionFailure: (state) => {
      return {
        ...state,
        isLoadingAction: false,
        errorAction: true,
      };
    },
    _getFiles: (state) => {
      return {
        ...state,
        isLoadingFiles: true,
      };
    },
    _getFilesSuccess: (state, { payload }) => {
      return {
        ...state,
        isLoadingFiles: false,
        filesError: null,
        files: payload,
      };
    },
    _getFilesFailure: (state, { payload }) => {
      return {
        ...state,
        isLoadingFiles: false,
        filesError: payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout.fulfilled, () => {
      return initialState;
    });
  },
});

const {
  _get,
  _getSuccess,
  _getFailure,
  _getById,
  _getByIdSuccess,
  _getByIdFailure,
  _setCurrentRequest,
  _clearCurrentRequest,
  _approveOrDenied,
  _approvedOrDeniedSuccess,
  _approvedOrDeniedFailure,
  _clearError,
  _clearActionError,
  _clearSuccess,
  _clearActionSuccess,
  _action,
  _actionSuccess,
  _actionFailure,
  _addLocationOrDate,
  _addLocationOrDateSuccess,
  _addLocationOrDateFailure,
  _clearAddLocationOrDateSuccess,
  _clearAddLocationOrDateError,
  _getFiles,
  _getFilesSuccess,
  _getFilesFailure,
  _addProjectManagerSuccess,
} = slice.actions;

export default slice.reducer;

export const fetch = createAsyncThunk(
  'requests/fetch',
  async (_, { dispatch }) => {
    dispatch(_get());
    try {
      const { data } = await axios.get('/workorders');

      const requests = data.map((request) => ({
        ...request,
        entrance_date: request.entrance_date
          ? formatDate(request.entrance_date, DATE_FORMATS.extended_date)
          : null,
        exit_date: request.exit_date
          ? formatDate(request.exit_date, DATE_FORMATS.extended_date)
          : null,
        ordered_work_order_ship_information: {
          ship_information_imo: request.ship.imo,
          ship_information_name: request.ship.name,
          ship_information_country: request.ship.country_id,
          ship_information_registration_port: request.ship.registration_port,
          ship_information_building_yards_name:
            request.ship.ship_building_yards_name,
          ship_information_building_yards_country:
            request.ship.ship_building_yards_country,
          ship_information_year: request.ship.year,
          ship_information_ship_type_name: request.ship.ship_type_name
            ? request.ship.ship_type_name
            : request.ship.ship_type_id,
          ship_information_ship_class_and_classification_society:
            request.ship.class_and_classification_society,
          ship_information_length: request.ship.length,
          ship_information_overall_length: request.ship.overall_length,
          ship_information_width: request.ship.width,
          ship_information_light_displacement: request.ship.light_displacement,
          ship_information_maximum_displacement:
            request.ship.maximum_displacement,
          ship_information_draft: request.ship.draft,
          ship_information_light_ship_draft: request.ship.light_ship_draft,
          ship_information_depth: request.ship.depth,
          ship_information_propulsion_system_type_id:
            request.ship.propulsion_system_type_id,
          ship_information_onboard_electrical_power_system_voltage:
            request.ship.onboard_electrical_power_system_voltage,
          ship_information_onboard_electrical_power_system_frequency:
            request.ship.onboard_electrical_power_system_frequency,
          ship_owner: {
            ship_owner_name: request.ship.ship_owner.name,
            ship_owner_fiscal_number: request.ship.ship_owner.fiscal_number,
            ship_owner_email: request.ship.ship_owner.email,
            ship_owner_phone_number: request.ship.ship_owner.phone_number,
            ship_owner_cell_phone: request.ship.ship_owner.cell_phone,
            ship_owner_street: request.ship.ship_owner.street,
            ship_owner_city: request.ship.ship_owner.city,
            ship_owner_country: request.ship.ship_owner.country,
            ship_owner_mailbox: request.ship.ship_owner.mailbox,
          },
        },
        work_order_submitter: {
          submitter_full_name: request.work_order_submitter.full_name,
          submitter_email: request.work_order_submitter.email,
          submitter_phone_number: request.work_order_submitter.phone_number,
          submitter_cell_phone: request.work_order_submitter.cell_phone,
          submitter_position_within_the_company:
            request.work_order_submitter.position_within_the_company,
          submitter_country: request.work_order_submitter.country,
        },
      }));

      const requestsUnderReview = requests.filter(
        (request) => request.status === 'requested',
      );

      const pendingRequests = requests.filter(
        (request) => request.status === 'pending',
      );

      const finishedRequests = requests.filter(
        (request) => request.status === 'finished',
      );

      const requestsInProgress = requests.filter(
        (request) =>
          request.status === 'on_holding' || request.status === 'in_progress',
      );

      dispatch(
        _getSuccess({
          requestsUnderReview,
          pendingRequests,
          finishedRequests,
          requestsInProgress,
        }),
      );
    } catch (error) {
      dispatch(_getFailure());
    }
  },
);

export const fetchById = createAsyncThunk(
  'requests/fetch_by_id',
  async (id, { dispatch }) => {
    try {
      await dispatch(_getById());
      const { data } = await axios.get(`/workorders/${id}`);

      const request = {
        ...data,
        entrance_date: data.entrance_date
          ? formatDate(data.entrance_date, DATE_FORMATS.extended_date)
          : null,
        exit_date: data.exit_date
          ? formatDate(data.exit_date, DATE_FORMATS.extended_date)
          : null,
        ordered_work_order_ship_information: {
          ship_information_imo: data.ship.imo,
          ship_information_name: data.ship.name,
          ship_information_country: data.ship.country_id,
          ship_information_registration_port: data.ship.registration_port,
          ship_information_building_yards_name:
            data.ship.ship_building_yards_name,
          ship_information_building_yards_country:
            data.ship.ship_building_yards_country,
          ship_information_year: data.ship.year,
          ship_information_ship_type_name: data.ship.ship_type_name
            ? data.ship.ship_type_name
            : data.ship.ship_type_id,
          ship_information_ship_class_and_classification_society:
            data.ship.class_and_classification_society,
          ship_information_length: data.ship.length,
          ship_information_overall_length: data.ship.overall_length,
          ship_information_width: data.ship.width,
          ship_information_light_displacement: data.ship.light_displacement,
          ship_information_maximum_displacement: data.ship.maximum_displacement,
          ship_information_draft: data.ship.draft,
          ship_information_light_ship_draft: data.ship.light_ship_draft,
          ship_information_depth: data.ship.depth,
          ship_information_propulsion_system_type_id:
            data.ship.propulsion_system_type_id,
          ship_information_onboard_electrical_power_system_voltage:
            data.ship.onboard_electrical_power_system_voltage,
          ship_information_onboard_electrical_power_system_frequency:
            data.ship.onboard_electrical_power_system_frequency,
          ship_owner: {
            ship_owner_name: data.ship.ship_owner.name,
            ship_owner_fiscal_number: data.ship.ship_owner.fiscal_number,
            ship_owner_email: data.ship.ship_owner.email,
            ship_owner_phone_number: data.ship.ship_owner.phone_number,
            ship_owner_cell_phone: data.ship.ship_owner.cell_phone,
            ship_owner_street: data.ship.ship_owner.street,
            ship_owner_city: data.ship.ship_owner.city,
            ship_owner_country: data.ship.ship_owner.country,
            ship_owner_mailbox: data.ship.ship_owner.mailbox,
          },
        },
        work_order_submitter: {
          submitter_full_name: data.work_order_submitter.full_name,
          submitter_email: data.work_order_submitter.email,
          submitter_phone_number: data.work_order_submitter.phone_number,
          submitter_cell_phone: data.work_order_submitter.cell_phone,
          submitter_position_within_the_company:
            data.work_order_submitter.position_within_the_company,
          submitter_country: data.work_order_submitter.country,
        },
      };

      dispatch(_setCurrentRequest(request));
      dispatch(_getByIdSuccess());
    } catch (error) {
      dispatch(_getByIdFailure(error.message));
    }
  },
);

export const approveEntry = createAsyncThunk(
  'requests/approve_entry',
  async (data, { dispatch }) => {
    dispatch(_approveOrDenied());
    try {
      await axios.patch(`/workorders/${data.id}`, {
        entrance_status: 'accepted',
      });

      dispatch(_approvedOrDeniedSuccess());
    } catch (error) {
      dispatch(_approvedOrDeniedFailure(error.message));
    }
  },
);

export const rejectEntry = createAsyncThunk(
  'requests/reject_entry',
  async (data, { dispatch }) => {
    dispatch(_approveOrDenied());
    try {
      await axios.patch(`/workorders/${data.id}`, {
        entrance_status: 'rejected',
      });

      dispatch(_approvedOrDeniedSuccess());
    } catch (error) {
      dispatch(_approvedOrDeniedFailure(error.message));
    }
  },
);

export const approveExit = createAsyncThunk(
  'requests/approve_exit',
  async (data, { dispatch }) => {
    dispatch(_approveOrDenied());
    try {
      await axios.patch(`/workorders/${data.id}`, {
        exit_status: 'accepted',
      });

      dispatch(_approvedOrDeniedSuccess());
    } catch (error) {
      dispatch(_approvedOrDeniedFailure(error.message));
    }
  },
);

export const rejectExit = createAsyncThunk(
  'requests/reject_exit',
  async (data, { dispatch }) => {
    dispatch(_approveOrDenied());
    try {
      await axios.patch(`/workorders/${data.id}`, {
        exit_status: 'rejected',
      });

      dispatch(_approvedOrDeniedSuccess());
    } catch (error) {
      dispatch(_approvedOrDeniedFailure(error.message));
    }
  },
);

export const setCurrentRequest = createAsyncThunk(
  'requests/set_current_request',
  async (data, { dispatch }) => {
    dispatch(_setCurrentRequest(data));
  },
);

export const clearCurrentRequest = createAsyncThunk(
  'requests/clear_current_request',
  async (_, { dispatch }) => {
    dispatch(_clearCurrentRequest());
  },
);

export const clearError = createAsyncThunk(
  'requests/clear_error',
  async (_, { dispatch }) => {
    dispatch(_clearError());
  },
);

export const clearSuccess = createAsyncThunk(
  'requests/clear_error',
  async (_, { dispatch }) => {
    dispatch(_clearSuccess());
  },
);

export const clearActionSuccess = createAsyncThunk(
  'requests/clear_action_error',
  async (_, { dispatch }) => {
    dispatch(_clearActionSuccess());
  },
);

export const clearActionError = createAsyncThunk(
  'requests/clear_action_error',
  async (_, { dispatch }) => {
    dispatch(_clearActionError());
  },
);

export const acceptRequest = createAsyncThunk(
  'requests/action_accept_request',
  async (id, { dispatch }) => {
    dispatch(_action());
    try {
      await axios.patch(`/workorders/${id}`, {
        status: STATUS.PENDING,
      });

      dispatch(_actionSuccess());
    } catch (error) {
      dispatch(_actionFailure());
    }
  },
);

export const startWork = createAsyncThunk(
  'requests/action_start_work',
  async (data, { dispatch }) => {
    dispatch(_action());
    try {
      await axios.patch(`/workorders/${data.id}`, {
        status: STATUS.PROGRESS,
      });

      dispatch(_actionSuccess());
    } catch (error) {
      dispatch(_actionFailure());
    }
  },
);

export const finishWork = createAsyncThunk(
  'requests/action_finish_work',
  async (data, { dispatch }) => {
    dispatch(_action());
    try {
      await axios.patch(`/workorders/${data.id}`, {
        status: STATUS.HOLDING,
      });

      dispatch(_actionSuccess());
    } catch (error) {
      dispatch(_actionFailure());
    }
  },
);

export const finalizeOrder = createAsyncThunk(
  'requests/action_finalize_order',
  async (data, { dispatch }) => {
    dispatch(_action());
    try {
      await axios.patch(`/workorders/${data.id}`, {
        status: STATUS.FINISHED,
      });

      dispatch(_actionSuccess());
    } catch (error) {
      dispatch(_actionFailure());
    }
  },
);

export const addProjectManager = createAsyncThunk(
  'requests/add_project_manager',
  async ({ id, projectManagerId }, { dispatch }) => {
    dispatch(_action());
    try {
      await axios.patch(`/workorders/${id}`, {
        product_manager_id: projectManagerId,
      });

      dispatch(
        _addProjectManagerSuccess({
          product_manager_id: projectManagerId,
          id,
        }),
      );
    } catch (error) {
      dispatch(_actionFailure());
    }
  },
);

export const addLocationOrDate = createAsyncThunk(
  'requests/add_location_or_date',
  async (data, { dispatch, getState }) => {
    const requestId = getState().requests.request.id;
    dispatch(_addLocationOrDate());
    try {
      await axios.patch(`/workorders/${requestId}`, {
        location_id: data.location ? data.location.id : undefined,
        direction: data.direction ? data.direction.id : undefined,
        location_value: data.locationDescription
          ? data.locationDescription
          : undefined,
        entrance_date: data.fullEntryDate
          ? addCurrentLocalTime(data.fullEntryDate)
          : undefined,
        exit_date: data.fullExitDate
          ? addCurrentLocalTime(data.fullExitDate)
          : undefined,
      });

      dispatch(_addLocationOrDateSuccess());
    } catch (error) {
      dispatch(_addLocationOrDateFailure());
    }
  },
);

export const clearAddLocationOrDateSuccess = createAsyncThunk(
  'requests/clear_add_location_or_date_success',
  async (_, { dispatch }) => {
    dispatch(_clearAddLocationOrDateSuccess());
  },
);

export const clearAddLocationOrDateError = createAsyncThunk(
  'requests/clear_add_location_or_date_error',
  async (_, { dispatch }) => {
    dispatch(_clearAddLocationOrDateError());
  },
);

export const fetchFiles = createAsyncThunk(
  'fetch/files',
  async (id, { dispatch }) => {
    try {
      dispatch(_getFiles());

      const response = await axios.get(`/workorders/${id}/attachments`);

      dispatch(_getFilesSuccess(response.data));
    } catch (error) {
      dispatch(_getFilesFailure(error.message));
    }
  },
);
