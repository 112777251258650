import { Dialog, Container, DialogContent } from '@mui/material';
import PropTypes from 'prop-types';

const PopUp = ({
  children,
  isVisible,
  setIsVisible,
  maxWidth,
  isFullWidth,
}) => {
  return (
    <Container>
      <Dialog
        maxWidth={maxWidth}
        fullWidth={isFullWidth}
        open={isVisible}
        onClose={setIsVisible}
        PaperProps={{
          style: { borderRadius: 8 },
        }}
      >
        <DialogContent>{children}</DialogContent>
      </Dialog>
    </Container>
  );
};

PopUp.defaultProps = {
  maxWidth: 'md',
  isFullWidth: true,
};

PopUp.propTypes = {
  children: PropTypes.node.isRequired,
  maxWidth: PropTypes.oneOf(['sm', 'md', 'xl']),
  isFullWidth: PropTypes.bool,
  isVisible: PropTypes.bool.isRequired,
  setIsVisible: PropTypes.func.isRequired,
};

export default PopUp;
