import { Box } from '@mui/material';
import PropTypes from 'prop-types';

import CustomButton from '../Button/CustomButton';

const ActivityControl = ({ title, color, onClick, disabled, isLoading }) => {
  return (
    <Box sx={{ flex: 1 }}>
      <CustomButton
        onClick={onClick}
        title={title}
        color={color}
        isLoading={isLoading}
        isDisabled={disabled}
      />
    </Box>
  );
};

ActivityControl.defaultProps = {
  isLoading: false,
  disabled: false,
};

ActivityControl.propTypes = {
  title: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default ActivityControl;
