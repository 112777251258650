import {
  Table,
  TableRow,
  TableCell,
  Typography,
  TableBody,
  TableContainer,
} from '@mui/material';
import PropTypes from 'prop-types';

import translationDict from '../../constants/translationDict';
import { dateFormattedAccordingLanguage } from '../../utils/formattedData';
import translateKeys from '../../utils/translateKeys';
import ContentCard from '../ContentCard';

const InformationTable = ({ item, type }) => {
  let data;
  let customData;

  switch (type) {
    case 'owner':
      data = item.ordered_work_order_ship_information.ship_owner;
      break;
    case 'order': {
      customData = {
        reference_code: item.reference_code,
        entrance_date: item.entrance_date
          ? dateFormattedAccordingLanguage(item.entrance_date, 'pt')
          : '-',
        exit_date: item.exit_date
          ? dateFormattedAccordingLanguage(item.exit_date, 'pt')
          : '-',
      };
      data = customData;
      break;
    }
    case 'work_order_submitter':
      data = item.work_order_submitter;
      break;
    default:
      data = item.ordered_work_order_ship_information;
  }

  const customTableCell = (value, isLabel) => {
    return (
      <TableCell align={isLabel ? 'left' : 'right'}>
        <Typography variant={isLabel ? 'body2' : 'body3'}>{value}</Typography>
      </TableCell>
    );
  };

  const isLastRow = (key) => {
    const keys = Object.keys(data);
    const lastIndex = keys.length - 1;

    return typeof data[keys[lastIndex]] !== 'object'
      ? key === lastIndex
      : key === lastIndex - 1;
  };

  const noBorder = { '& td': { border: 0 } };

  const renderTable = () => {
    return Object.keys(data).map((value, key) => {
      if (
        typeof data[value] !== 'object' &&
        value !== 'id' &&
        value !== 'ship_type_name'
      ) {
        return (
          <TableRow key={value} sx={isLastRow(key) ? noBorder : {}}>
            {customTableCell(translateKeys(value, translationDict), true)}
            {customTableCell(
              data[value] ? translateKeys(data[value], translationDict) : '---',
            )}
          </TableRow>
        );
      }
      return null;
    });
  };

  return (
    <ContentCard>
      <TableContainer>
        <Table>
          <TableBody>{renderTable(data)}</TableBody>
        </Table>
      </TableContainer>
    </ContentCard>
  );
};

InformationTable.propTypes = {
  item: PropTypes.shape({
    email: PropTypes.string,
    name: PropTypes.string,
    phone_number: PropTypes.string,
    country: PropTypes.string,
    imo: PropTypes.string,
    width: PropTypes.string,
    length: PropTypes.string,
    registration_port: PropTypes.string,
    ship_type: PropTypes.string,
    year: PropTypes.string,
    gross_tonnage: PropTypes.string,
  }).isRequired,
  type: PropTypes.oneOf(['owner', 'ship', 'order']).isRequired,
};

export default InformationTable;
