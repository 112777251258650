import { Box, Container } from '@mui/material';

const Footer = () => {
  return (
    <Box
      sx={{
        width: '100%',
        height: 'auto',
        display: 'flex',
        bottom: 0,
        left: 0,
        justifyContent: 'center',
        p: 2,
      }}
    >
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box>
          <a href="https://chuva.io/" rel="noreferrer" target="_blank">
            <img
              src="/static/logo/powered_by_chuva.svg"
              alt="Chuva logo"
              loading="lazy"
              width={80}
              height="auto"
            />
          </a>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
