import { Box, Chip, useTheme } from '@mui/material';
import PropTypes from 'prop-types';

const status = (type) => {
  const theme = useTheme();
  switch (type) {
    case 'approved':
      return {
        color: theme.palette.success.main,
        backgroundColor: 'rgba(123, 198, 126, 0.1)',
      };
    case 'pending':
      return {
        color: theme.palette.warning.main,
        backgroundColor: 'rgba(255, 181, 71, 0.1)',
      };
    case 'denied':
      return {
        color: theme.palette.error.main,
        backgroundColor: 'rgba(254, 67, 54, 0.1)',
      };
    case 'info':
      return {
        color: theme.palette.primary.main,
        backgroundColor: 'rgba(60, 120, 240, 0.1)',
      };
    default:
      return {
        color: theme.palette.disabled.main,
        backgroundColor: 'rgba(158, 158, 158, 0.1)',
      };
  }
};
const ChipButton = ({ label, type }) => {
  return (
    <Box sx={{ flex: 1 }}>
      <Chip
        style={{
          width: '100%',
          color: `${status(type).color}`,
          padding: 8,
          backgroundColor: `${status(type).backgroundColor}`,
        }}
        label={label}
      />
    </Box>
  );
};

ChipButton.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['approved', 'pending', 'denied', 'info']).isRequired,
};

export default ChipButton;
