import { Box, Typography } from '@mui/material';
import { Formik } from 'formik';

import useAuth from '../../../hooks/useAuth';
import { useSelector } from '../../../store';
import {
  emailValidationSchema,
  passwordValidationSchema,
  getCombinedValidationSchema,
} from '../../../yupValidation';
import CustomButton from '../../Button/CustomButton';
import TextInput from '../../Inputs/TextInput';

const LoginForm = () => {
  const { isLoading } = useSelector((state) => state.auth);
  const { login } = useAuth();
  const combinedValidationSchema = getCombinedValidationSchema([
    emailValidationSchema(),
    passwordValidationSchema(),
  ]);

  const initialValues = {
    email: null,
    password: null,
    submit: null,
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={combinedValidationSchema}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await login(values.email, values.password);
        } catch (err) {
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              marginBottom: 3,
            }}
          >
            <Typography variant="h5">Iniciar Sessão</Typography>
            <Typography color="textSecondary">
              Iniciar sessão na plataforma interna
            </Typography>
          </Box>
          <Box sx={{ marginBottom: 3 }}>
            <TextInput
              name="email"
              error={Boolean(touched.email && errors.email)}
              label="Email"
              helperText={touched.email && errors.email}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.email}
            />
          </Box>
          <Box sx={{ marginBottom: 3 }}>
            <TextInput
              name="password"
              type="password"
              error={Boolean(touched.password && errors.password)}
              label="Palavra-passe"
              helperText={touched.password && errors.password}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.password}
            />
          </Box>
          <Box>
            <CustomButton
              title="INICIAR"
              type="contained"
              color="primary"
              action="submit"
              isLoading={isLoading}
            />
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default LoginForm;
