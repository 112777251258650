import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';

import animationData from '../../assets/lottiefiles/noMessages.json';

const NoMessages = (props) => {
  const { message } = props;
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
  };

  return (
    <Box>
      <Lottie options={defaultOptions} height={300} width={300} />
      <Typography variant="h5" align="center">
        {message}
      </Typography>
    </Box>
  );
};

NoMessages.propTypes = {
  message: PropTypes.string.isRequired,
};

export default NoMessages;
