import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  Typography,
  TableCell,
} from '@mui/material';
import PropTypes from 'prop-types';

import ContentCard from '../ContentCard';

const DocumentsList = ({ documents, listType }) => {
  const data = documents.filter((filtered) => filtered.type === listType);

  const getTitle = (value) =>
    value === 'pro-formas' ? 'Pró-Forma' : 'Fatura/Recibo';

  const handleRedirectSambaDocument = (type, id) => {
    const documentType =
      type === 'pro-formas' ? 'pro_formas' : 'faturas_recibo';

    window.open(
      `${process.env.REACT_APP_SAMBA_BASE_URL}/${documentType}/${id}`,
      '_blank',
      'noreferrer',
    );
  };

  const hasData = () => {
    return data.length > 0;
  };

  const noBorder = { '& td': { border: 0 } };

  return (
    <ContentCard>
      <TableContainer>
        <Table>
          <TableBody>
            {hasData() ? (
              data.map((document, index) => {
                return (
                  <TableRow
                    key={document.document_id}
                    sx={{
                      ...(index === data.length - 1 ? noBorder : {}),
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      handleRedirectSambaDocument(
                        document.type,
                        document.document_id,
                      );
                    }}
                  >
                    <TableCell sx={{ width: '100%' }}>
                      <Typography variant="body3">
                        {`${getTitle(document.type)} ${
                          document.document_number
                        }/${document.serial_number}`}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ width: '100%' }}>
                      <LinkOutlinedIcon />
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableCell sx={{ border: 0 }}>
                <Typography variant="body3">Sem dados</Typography>
              </TableCell>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </ContentCard>
  );
};

DocumentsList.propTypes = {
  documents: PropTypes.shape({
    document_id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    serial_number: PropTypes.string.isRequired,
    document_number: PropTypes.number.isRequired,
  }).isRequired,
  listType: PropTypes.oneOf(['pro-formas', 'invoice_receipts']).isRequired,
};

export default DocumentsList;
