import { Box, CircularProgress, Tabs, Tab } from '@mui/material';
import React, { useEffect, useState, useCallback, useContext } from 'react';
import { Helmet } from 'react-helmet';

import BasicTable from '../../components/BasicTable';
import CustomTabPanel from '../../components/CustomTabPanel';
import Error from '../../components/Error';
import NoData from '../../components/NoData';
import PopUp from '../../components/PopUp';
import RequestDetailsDialog from '../../components/RequestDetailsDialog';
import SectionHeader from '../../components/SectionHeader';
import Wrapper from '../../components/Wrapper';
import AuthContext from '../../contexts/JWTContext';
import {
  getCurrentRequest,
  isAllowedToUpdateProjectManager,
} from '../../selectors/requestsSelectors';
import {
  getProjectManagers,
  getIsLoadingProjectManagers,
} from '../../selectors/usersSelectors';
import { fetch as fetchLocations } from '../../slices/locations';
import {
  fetch as fetchRequests,
  setCurrentRequest,
  clearCurrentRequest,
  addProjectManager,
  acceptRequest,
} from '../../slices/requests';
import { fetch as fetchProjectManagers } from '../../slices/users';
import { useDispatch, useSelector } from '../../store';
import isAdmins from '../../utils/isAdmins';
import isProductionManagements from '../../utils/isProductionManagements';

const HomePage = () => {
  const dispatch = useDispatch();
  const [tabValue, setTabValue] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const request = useSelector(getCurrentRequest);
  const {
    requestsUnderReview,
    pendingRequests,
    finishedRequests,
    requestsInProgress,
    isLoading,
    error,
  } = useSelector((state) => state.requests);
  const { locations } = useSelector((state) => state.locations);
  const projectManagers = useSelector(getProjectManagers);
  const isLoadingProjectManagers = useSelector(getIsLoadingProjectManagers);
  const havePermissionToUpdateProjectManager = useSelector((state) =>
    isAllowedToUpdateProjectManager(state, request?.id),
  );

  const { user } = useContext(AuthContext);

  const userGroup = user.groups[0];

  const showPendingRequests = !isProductionManagements(userGroup);

  const showUnderReview = isAdmins(userGroup);

  const tabIndex = {
    zero: 0,
    one: 1,
    two: 2,
    three: 3,
  };

  useEffect(() => {
    dispatch(fetchProjectManagers());
    dispatch(fetchRequests());
    dispatch(fetchLocations());
  }, [dispatch]);

  const handleFetchRequests = useCallback(() => {
    dispatch(fetchRequests());
  }, [dispatch]);

  const handleTabChange = useCallback((event, newValue) => {
    setTabValue(newValue);
  }, []);

  const handleCloseRequestDetails = useCallback(() => {
    dispatch(clearCurrentRequest());
    setShowModal(false);
  }, [dispatch]);

  const handleProjectManagerChange = useCallback(
    async (manager) => {
      if (manager && manager.id) {
        const data = {
          id: request.id,
          projectManagerId: manager.id,
        };
        await dispatch(addProjectManager(data));
      }
    },
    [dispatch, request],
  );

  const renderTable = useCallback(
    (data, onClickHandler) => {
      if (isLoading) {
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <CircularProgress />
          </Box>
        );
      }

      if (error) {
        return <Error onClick={handleFetchRequests} />;
      }

      if (data && data.length > 0) {
        return (
          <BasicTable requests={data} onActionButtonClick={onClickHandler} />
        );
      }

      return <NoData message="Nenhuma solicitaçāo encontrada" />;
    },
    [isLoading, error, handleFetchRequests],
  );

  const handleSelectRequest = async (item) => {
    await dispatch(setCurrentRequest(item));
    setShowModal(true);
  };

  const renderRequestUnderReviewTable = useCallback(
    () => renderTable(requestsUnderReview, handleSelectRequest),
    [requestsUnderReview, renderTable],
  );

  const renderPendingRequestTable = useCallback(
    () => renderTable(pendingRequests, handleSelectRequest),
    [pendingRequests, renderTable],
  );

  const renderInProgressRequestTable = useCallback(
    () => renderTable(requestsInProgress, handleSelectRequest),
    [requestsInProgress, renderTable],
  );

  const renderFinishedRequestTable = useCallback(
    () => renderTable(finishedRequests, handleSelectRequest),
    [finishedRequests, renderTable],
  );

  const handleSubmitAcceptRequest = useCallback(async () => {
    await dispatch(acceptRequest(request.id));
  }, [dispatch, request]);

  const getInProgressTabIndex = () => {
    if (showUnderReview) {
      return tabIndex.two;
    }
    if (showPendingRequests) {
      return tabIndex.one;
    }
    return tabIndex.zero;
  };

  const getFinishedTabIndex = () => {
    if (showUnderReview) {
      return tabIndex.three;
    }
    if (showPendingRequests) {
      return tabIndex.two;
    }
    return tabIndex.one;
  };

  return (
    <>
      <Helmet>
        <title>Solicitaçōes | Cabnave</title>
      </Helmet>
      <Wrapper>
        <SectionHeader
          title="Solicitaçōes"
          description="Lista de solicitações"
          isPrimary
        />
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="custom tabs"
              variant="fullWidth"
            >
              {showUnderReview && <Tab label="EM ANÁLISE" />}
              {showPendingRequests && <Tab label="PENDENTES" />}
              <Tab label="EM CURSO" />
              <Tab label="FINALIZADOS" />
            </Tabs>
          </Box>
          {showUnderReview && (
            <CustomTabPanel value={tabValue} index={0}>
              <Box sx={{ mt: 3 }}>{renderRequestUnderReviewTable()}</Box>
            </CustomTabPanel>
          )}
          {showPendingRequests && (
            <CustomTabPanel value={tabValue} index={showUnderReview ? 1 : 0}>
              <Box sx={{ mt: 3 }}>{renderPendingRequestTable()}</Box>
            </CustomTabPanel>
          )}
          <CustomTabPanel value={tabValue} index={getInProgressTabIndex()}>
            <Box sx={{ mt: 3 }}>{renderInProgressRequestTable()}</Box>
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={getFinishedTabIndex()}>
            <Box sx={{ mt: 3 }}>{renderFinishedRequestTable()}</Box>
          </CustomTabPanel>
        </Box>

        {showModal && request && (
          <PopUp setIsVisible={handleCloseRequestDetails} isVisible={showModal}>
            <RequestDetailsDialog
              locations={locations}
              onGoBack={handleCloseRequestDetails}
              request={request}
              projectManagers={projectManagers}
              isLoadingProjectManagers={isLoadingProjectManagers}
              havePermissionToUpdateProjectManager={
                havePermissionToUpdateProjectManager
              }
              handleAddProjectManager={handleProjectManagerChange}
              handleSubmitAcceptRequest={handleSubmitAcceptRequest}
              userRole={userGroup}
            />
          </PopUp>
        )}
      </Wrapper>
    </>
  );
};

export default HomePage;
