import { format, parseISO } from 'date-fns';

/**
 * Formats a given date string to a specified ISO format.
 *
 * @param {string} date - The date string in ISO format to be formatted.
 * @param {string} isoFormat - The ISO format string (e.g., 'yyyy-MM-dd') to format the date.
 * @returns {string} - The formatted date string according to the specified ISO format.
 */
const formatDate = (date, isoFormat) => {
  const parsedDate = parseISO(date);
  return format(parsedDate, isoFormat);
};

export default formatDate;
