import { STATUS } from '../utils/constants';

export const isAllowedToUpdateProjectManager = (state, id) => {
  const { user } = state.auth;
  const { request } = state.requests;
  const isAdmin = user?.groups.includes('Admins');
  const isMatchingRequest = request?.id === id;
  const isInProgress =
    request?.status === STATUS.PENDING ||
    request?.status === STATUS.PROGRESS ||
    request?.status === STATUS.HOLDING;

  return isMatchingRequest && isAdmin && isInProgress;
};

export const getCurrentRequest = (state) => {
  const { users } = state.users;
  const { request } = state.requests;

  if (!request) {
    return;
  }

  if (request.product_manager_id) {
    const productManager = users.find(
      (user) => user.id === request.product_manager_id,
    );
    const currentRequest = {
      product_manager: productManager,
      ...request,
    };

    return currentRequest;
  }

  return request;
};
