import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Container,
} from '@mui/material';
import PropTypes from 'prop-types';

import CustomButton from '../Button/CustomButton';

const LogOffPopUpDialog = ({
  title,
  description,
  isVisible,
  setIsVisible,
  onClickPrimary,
  onClickSecondary,
  primaryText,
  secondaryText,
  secondaryTextColor,
  primaryTextColor,
}) => {
  return (
    <Container>
      <Dialog open={isVisible} onClose={() => setIsVisible(false)}>
        <DialogTitle align="center">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText align="center">{description}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <CustomButton
            onClick={onClickPrimary}
            title={primaryText}
            type="text"
            shadow={false}
            color={primaryTextColor}
          />
          <CustomButton
            onClick={onClickSecondary}
            title={secondaryText}
            type="text"
            shadow={false}
            color={secondaryTextColor}
          />
        </DialogActions>
      </Dialog>
    </Container>
  );
};

LogOffPopUpDialog.defaultProps = {
  primaryTextColor: 'primary',
  secondaryTextColor: 'primary',
};

LogOffPopUpDialog.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired,
  setIsVisible: PropTypes.func.isRequired,
  onClickPrimary: PropTypes.func.isRequired,
  onClickSecondary: PropTypes.func.isRequired,
  primaryText: PropTypes.string.isRequired,
  secondaryText: PropTypes.string.isRequired,
  secondaryTextColor: PropTypes.string,
  primaryTextColor: PropTypes.string,
};

export default LogOffPopUpDialog;
